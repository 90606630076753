'use client';

import { FC } from 'react';
import { Image } from '../Image/Image';

import styles from './HeaderMenu.module.scss';

interface Props {}

const LOGO_WIDTH = 162;
const LOGO_HEIGHT = 64;

export const HeaderMenuLogo: FC<Props> = () => (
  <Image
    src={'/images/carma-logo.svg'}
    alt="Carma"
    className={styles.logoImage}
    width={LOGO_WIDTH}
    height={LOGO_HEIGHT}
    priority
  />
);
